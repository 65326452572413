<template>
    <el-card class="box-card">
        <div slot="header" class="clearfix">
            <h3 class="inBlock">门店投诉</h3>
        </div>
        <div class="box">
            <div class="top">
                <el-form ref="form" :model="form" label-width="85px">
                    <el-form-item label="用户手机号">
                        <el-input
                        placeholder="请输入用户手机号"
                        size="mini"
                        v-model="form.user_phone"
                        ></el-input>
                    </el-form-item>
                    <!-- <el-form-item label="门店名称">
                        <el-input
                        placeholder="请输入门店名称"
                        size="mini"
                        v-model="form.store_name"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="门店电话">
                        <el-input
                        placeholder="请输入门店电话"
                        size="mini"
                        v-model="form.phone"
                        ></el-input>
                    </el-form-item> -->
                </el-form>
                <router-link :to="{path:'./components/shopcomplaintModel'}">
                  <el-button
                    type="primary"
                    
                    size="mini"
                    style="margin-right:10px"
                >投诉问题</el-button>
              </router-link>
                <!-- <el-button size="mini" type="primary" @click="addInfo">新增投诉问题</el-button> -->
                <el-button size="mini" type="primary" @click="search">筛选</el-button>
            </div>
            <el-table
                border
                :data="tableData"
                style="width: 100%"
            >
                <el-table-column prop="user_name" label="用户昵称"> </el-table-column>
                <el-table-column prop="user_phone" label="用户手机号"> </el-table-column>
                <el-table-column prop="store_name" label="所属门店"> </el-table-column>
                <el-table-column label="投诉时间">
                     <template slot-scope="scope">
                        <div> {{scope.row.createtime | datefilter}}</div>
                    </template>
                </el-table-column>
                <el-table-column prop="content" label="投诉类型"></el-table-column>
                <el-table-column prop="content" label="投诉内容"></el-table-column>
                <el-table-column label="图片">
                    <template slot-scope="scope">
                        <img class="listimg" :src="imgurl + scope.row.enclosure[0]" alt="" />
                    </template>
                </el-table-column>
                <el-table-column label="处理状态">
                    <template slot-scope="scope">
                        <div>{{scope.row.status == 1 ? '已处理' : '未处理'}}</div>
                    </template>
                </el-table-column>
                <el-table-column label="操作">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.status == 2" type="primary" size="mini" @click="manageClick(scope.row.id)">处理</el-button>
                        <el-button v-else type="info" size="mini">处理</el-button>
                    </template>
                </el-table-column>
            </el-table>
            <el-pagination
                @current-change="CurrentChange"
                layout="total, prev, pager, next, jumper"
                :total="total"
            >
            </el-pagination>

        </div>
        	<!-- 添加活动 -->
        <!-- <el-dialog :title="title" :visible.sync="addDialogVisible" width="40%">
            <el-form
                :model="addFrom"
                ref="liveFrom"
                label-width="100px"
            >
                <el-form-item label="活动标题">
                    <el-input v-model="addFrom.name"></el-input>
                </el-form-item>
                <el-form-item label="是否显示">
                <el-radio v-model="addFrom.status" :label="1">是</el-radio>
                <el-radio v-model="addFrom.status" :label="2">否</el-radio>
                </el-form-item>
            </el-form>
        <span slot="footer" class="dialog-footer">
            <el-button type="warning" @click="addDialogVisible = false">关闭</el-button>
            <el-button type="primary" @click="submitProSele">确 定</el-button>
        </span>
    </el-dialog> -->
    </el-card>
</template>
<script>
import { storeComplaintList, storeComplaintFeedback } from "@/api/api.js";
import { getSystemsettings } from "../../api/getSystemsetting.js";
export default {
    name: "shopComplaint",
    components: {},
    data(){
        return{
            tableData: [],
            form:{
                is_revoke: '', //是否已撤销：1.是，2.否
                store_name: '', //查询条件（门店名称）
                store_phone: '', //查询条件（门店电话）
                user_name: '', //查询条件（用户名称）
                user_phone: '', //查询条件（用户电话）
                status: '', //查询条件（投诉状态：1.已处理，2.未处理）
                page:1,
                limit:10
            },
            addFrom:{
                name: '',
                status: '',
            },
            total: 0,
            imgurl: '',
            addDialogVisible: false,
            title: '添加投诉问题'
        }
    },
    //计算属性 依赖缓存,多对一(即多个影响一个),不支持异步
    computed: {},
    //生命周期 - 挂载完成（可以访问DOM元素）
    mounted() {
        getSystemsettings.then((res) => {
            this.imgurl = res;
        });
    },
    methods:{
        search() {
            this.form.page = 1;
            this.getlist();
        },
        CurrentChange(e) {
            this.form.page = e;
            this.getlist();
        },

        async getlist(){
            const { data } = await storeComplaintList(this.form);
            if (data.code != 200) return this.$message.error(data.data);
            console.log(data);
            this.tableData = data.data.data;
            this.total = data.data.total;
            console.log(this.tableData);
        },
        // 处理
        manageClick(id){
            this.$confirm('本条投诉标记为已处理?', '提示', {
                confirmButtonText: '确定',
                cancelButtonText: '取消',
                type: 'warning'
                }).then(async () => {
                    const { data } = await storeComplaintFeedback({id: id});
                    if (data.code != 200) return this.$message.error(data.data);
                    this.$message({
                        type: 'success',
                        message: '删除成功!'
                    });
                    this.getlist();
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消删除'
                    });          
            });
        },
        
        addInfo(){
            this.addDialogVisible = true
        },
        submitProSele(){
            this.addDialogVisible = false
        },
    },
    //生命周期 - 创建完成（可以访问当前this实例）
    created() {
        this.getlist();
    },
    beforeCreate() {}, //生命周期 - 创建之前
    beforeMount() {}, //生命周期 - 挂载之前
    beforeUpdate() {}, //生命周期 - 更新之前
    updated() {}, //生命周期 - 更新之后
    beforeDestroy() {}, //生命周期 - 销毁之前
    destroyed() {}, //生命周期 - 销毁完成
    activated() {}, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
<style lang="less">
.top {
  margin: 10px;
}
.el-form {
  display: flex;
  flex-wrap: wrap;
}
.listimg{
    width: 80px;
    height: 80px;
}
</style>